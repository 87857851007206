import styled from "styled-components";

export const BoxLogin = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const BoxLoginWrap = styled.div`
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 1px 1.5px rgba(0, 0, 0, 0.15);
  padding: 25px;
`;

export const BoxLoginTitle = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 25px;
`;

export const BoxLoginButton = styled.div`
  text-align: right;
  margin-top: 30px;
`;
