import React, { useState } from "react";
import {
  useCollection,
  useMetafields,
  useMetafieldsDie,
  useProducts,
} from "../../helpers/query";
import SpinFullscreen from "../Spin/SpinFullscreen";
import { useParams } from "react-router-dom";
import { Card, ConfigProvider, List, Typography, Tag, Space } from "antd";
import MetafieldModal from "../Metafield/MetafieldModal";
import { getAccount } from "../../helpers/auth";

const ProductList = ({ date, weekday }) => {
  const accountExists = getAccount();
  const [statusModal, setStatusModal] = useState(false);
  const [currentVar, setCurrentVar] = useState(0);
  const [metaData, setMetaData] = useState(null);

  const { collectionid } = useParams();
  const { isLoading: collectionQuery_loading, data: collectionQuery } = useCollection(collectionid); /* prettier-ignore */
  const { isLoading: productsQuery_loading, data: productsQuery } = useProducts(); /* prettier-ignore */
  const { isLoading: metafields_loading, data: metafieldsQuery } = useMetafields(date); /* prettier-ignore */
  const { isLoading: metafieldsDie_loading, data: metafieldsDieQuery } = useMetafieldsDie(weekday); /* prettier-ignore */
  if (collectionQuery_loading || productsQuery_loading || metafields_loading) return <SpinFullscreen />; /* prettier-ignore */

  const productsInCol = productsQuery.products.filter(product => product.vendor === collectionQuery.collection.rules[0].condition); /* prettier-ignore */
  const onCardClick = (metaData, variant) => {
    setMetaData(metaData);
    setCurrentVar(variant);
    setStatusModal(true);
  };

  if (collectionQuery_loading || productsQuery_loading || metafields_loading || metafieldsDie_loading) return <SpinFullscreen />; /* prettier-ignore */
  return (
    <ConfigProvider>
      <List
        grid={{ gutter: [0, 30] }}
        dataSource={productsInCol}
        renderItem={
          (product) =>
        <List.Item>
          <Card title={<Typography.Text strong style={{ color: "#FFF" }}>{product.title}</Typography.Text>} 
                headStyle={{ background: "#6c757d" }} bodyStyle={{ background: "#EbEbEb" }}>
            <List
              grid={{ 
                gutter: 16,
                xs: 2,
                sm: 2,
                md: 5,
                lg: 5,
                xl: 9,
                xxl: 9 
              }}
              dataSource={product.variants}
              renderItem={(variant) => {
              const metaFinder = metafieldsQuery.metafields.find(metafield => metafield.owner_id === variant.id)
              const metaDieFinder = metafieldsDieQuery.metafields.find(metafield => metafield.owner_id === variant.id)
              const note = metaFinder?.value ? JSON.parse(metaFinder.value)?.note : "";
              const noteDie = metaDieFinder?.value ? JSON.parse(metaDieFinder.value)?.note : "";
              return (
                <List.Item>
                  <Card onClick={() => onCardClick(metaDieFinder || metaFinder, variant)} size="small" hoverable title={variant.option1}
                        headStyle={metaDieFinder ? { background: "#F30", color: "#FFF" } : metaFinder && { background: "#28a745", color: "#FFF" }}>
                    {String(note || noteDie).includes("stay") ? <Space size={[0, 8]}><Tag color="cyan">Nhân viên</Tag><Tag color="magenta">Tại chỗ</Tag> </Space>
                                                  : String(note || noteDie).includes("admin") ? <Tag color="cyan">Nhân viên</Tag> 
                                                  : (note || noteDie) ? <Tag color="green">Khách đặt Online</Tag>
                                                  : "..." }
                  </Card>
                </List.Item> /* prettier-ignore */
              );
            }}
            />
          </Card>
        </List.Item> /* prettier-ignore */
        }
      />
      <MetafieldModal
        role={accountExists?.role}
        metaData={metaData}
        currentVar={currentVar}
        date={date}
        weekday={weekday}
        statusModal={statusModal}
        setStatusModal={setStatusModal}
      />
    </ConfigProvider>
  );
};
export default ProductList;
