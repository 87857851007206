import React, { useState } from "react";
import { ConfigProvider, DatePicker, Divider } from "antd";

import dayjs from "dayjs";
import locale from "antd/locale/vi_VN";
import "dayjs/locale/vi";
import ProductList from "../../components/Product/ProductList";

const ProductPage = () => {
  const [date, setDate] = useState(dayjs().format("DDMMYYYY"));
  const [weekday, setWeekday] = useState(dayjs().format("dddd"));
  const onChangeDate = (value) => {
    setDate(dayjs(value).format("DDMMYYYY"));
    setWeekday(dayjs(value).format("dddd"));
  };
  return (
    <ConfigProvider locale={locale}>
      <div className="boxContainer">
        <div className="boxWrapper">
          <div className="boxHead">
            <h2>Chọn mốc thời gian</h2>
            <DatePicker className="isDatePicker" defaultValue={dayjs()} onChange={onChangeDate} />
          </div>
          <Divider />
          <div className="boxBody">
            <ProductList date={date} weekday={weekday} />
          </div>
        </div>
      </div>
    </ConfigProvider> /* prettier-ignore */
  );
};
export default ProductPage;
