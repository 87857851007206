import React from "react";
import { Modal, Form, Input, Button, Typography } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { updateAccountFn } from "../../helpers/account";
import { toast } from "react-toastify";
import SpinFullscreen from "../Spin/SpinFullscreen";

const ResetPassModal = ({ accountExists, statusModal, setStatusModal }) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { isLoading: update_account_loading, mutateAsync: update_account } =
    useMutation({
      mutationFn: (data) => updateAccountFn(accountExists?._id, data),
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries(["accounts"]);
          toast.success(data.message);
          setStatusModal(false);
        }
      },
      onError: (error) => toast.error(error.message),
    });
  const saveModal = () => setStatusModal(false);
  const cancelModal = () => setStatusModal(false);
  const onResetPass = (values) => {
    form.resetFields();
    if (values.oldPass === values.newPass) {
      toast.error("Mật khẩu cũ và mật khẩu mới giống nhau");
      return false;
    } else update_account(values);
  };
  if (update_account_loading) return <SpinFullscreen />;
  return (
    <Modal
      classNames={{ header: "resetPassModalHeader", body: "resetPassModalBody" }} /* prettier-ignore */
      title={<Typography.Title level={3}>Đổi mật khẩu</Typography.Title>}
      onOk={saveModal}
      onCancel={cancelModal}
      open={statusModal}
      centered
      footer={false}
    >
      <Form
        form={form}
        onFinish={onResetPass}
        labelCol={{ span: 6 }}
        labelAlign="left"
      >
        <Form.Item hidden name="type" initialValue="password">
          <Input />
        </Form.Item>
        <Form.Item
          label="Mật khẩu cũ"
          name="oldPass"
          rules={[
            {
              required: true,
              message: "Mật khẩu cũ không được để trống!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Mật khẩu mới"
          name="newPass"
          rules={[
            {
              required: true,
              message: "Mật khẩu mới không được để trống!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <div className="text-right">
          <Button type="primary" htmlType="submit">
            Cập nhật
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ResetPassModal;
