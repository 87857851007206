import LoginMain from "../pages/Account/LoginMain";
import NotFoundMain from "../pages/NotFound/NotFoundMain";
import LogoutMain from "../pages/Account/LogoutMain";
import SettingMain from "../pages/Setting/SettingMain";
import ProductPage from "../pages/Product/ProductPage";

export const routes = [
  {
    path: "/login",
    name: "Login",
    element: <LoginMain />,
    isNav: false,
    isPrivate: false,
  },
  {
    path: "/logout",
    name: "Logout",
    element: <LogoutMain />,
    isNav: false,
    isPrivate: false,
  },
  {
    path: "/setting",
    name: "Setting",
    element: <SettingMain />,
    isNav: true,
    isPrivate: true,
  },
  {
    path: "/collections/:collectionid",
    name: "Collection",
    element: <ProductPage />,
    isNav: true,
    isPrivate: true,
  },
  {
    path: "*",
    name: "NotFound",
    element: <NotFoundMain />,
    isNav: true,
    isPrivate: true,
  },
];
