import React from "react";
import { Modal, Form, Radio, Button, Space, Popconfirm } from "antd";
import TextArea from "antd/es/input/TextArea";
import { addMetafieldsFn, deleteMetafieldsFn } from "../../helpers/query";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import SpinFullscreen from "../Spin/SpinFullscreen";

const MetafieldModal = ({
  role,
  metaData,
  currentVar,
  date,
  weekday,
  statusModal,
  setStatusModal,
}) => {
  const metaid = metaData?.id || null;
  const varid = currentVar?.id || 0;
  const queryClient = useQueryClient();
  const { isLoading: metafields_delete_loading, mutateAsync: metafields_delete } = useMutation({ 
    mutationFn: async (id) => deleteMetafieldsFn(id),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["metafields", date]);
      queryClient.invalidateQueries(["metafieldsDie"]);
      setStatusModal(false)
      toast.success(data.message);
    },
    onError: (error) => toast.error(error.message),
  }); /* prettier-ignore */

  const { isLoading: metafields_add_loading, mutateAsync: metafields_add } =
    useMutation({
      mutationFn: (data) => addMetafieldsFn(data),
      onSuccess: (data) => {
        if (!data) return false;

        if (data.metafields.namespace === "once")
          queryClient.setQueryData(["metafields", date], (odd) => {
            return {
              message: data.message,
              metafields: [...odd.metafields, data.metafields],
            };
          });
        else
          queryClient.setQueryData(["metafieldsDie", weekday], (odd) => {
            return {
              message: data.message,
              metafields: [...odd.metafields, data.metafields],
            };
          });

        setStatusModal(false);
        toast.success(data.message);
      },
      onError: (error) => toast.error(error.message),
    });
  /* prettier-ignore */

  const updateEditModalVariant = async (value) => {
    const data = {
      varid,
      note: Object.values(value)[0],
      status: Object.values(value)[1],
      date,
      weekday
    };
    if (metaid) {
      await metafields_delete(metaid);
      await metafields_add(data);
    } else await metafields_add(data);
  };

  if (metafields_add_loading || metafields_delete_loading) return <SpinFullscreen />; /* prettier-ignore */

  return (
    <Modal
      title={role !=="admin" ? "Thông tin biến thể" : metaid ? "Chỉnh sửa biến thể" : "Khởi tạo biến thể"}
      open={statusModal}
      onCancel={() => setStatusModal(false)}
      centered
      footer={false}
    >
      <Form labelCol={{ span: 24 }} onFinish={updateEditModalVariant}>  
        <Form.Item initialValue={metaData?.value ? JSON.parse(metaData.value)?.note : ""} name={`note${varid}`} label="Ghi chú" rules={[{ required: true, message: "Vui lòng nhập ghi chú", }]}>
          <TextArea maxLength={500} rows={15} readOnly={role !== "admin"}/>
        </Form.Item>
        <Form.Item hidden={role !== "admin"} initialValue={metaData?.namespace || "once"} name={`status${varid}`}>
          <Radio.Group>
            <Radio value="once">Đặt một lần</Radio>
            <Radio value="die">Đặt chết sân</Radio> 
          </Radio.Group> 
        </Form.Item>
        { role === "admin" && <Space className="space-block justify-content-end">
          {metaData && 
            <Popconfirm title="Bạn có chắc muốn xóa dữ liệu này?" cancelText="Hủy bỏ" okText="Tiếp tục" onConfirm={() => metafields_delete(metaData?.id)}>
              <Button danger size="danger">Xóa</Button>
            </Popconfirm>
          }
          <Button type="primary" htmlType="submit" size="large">Cập nhật</Button>
        </Space>}
      </Form>
    </Modal>
    /* prettier-ignore */
  );
};

export default MetafieldModal;
