import { Button, Divider } from "antd";
import React, { useState } from "react";
import { Space } from "antd";
import { getAccount } from "../../helpers/auth";
import { IsAdmin } from "./style";
import { GrUserAdmin } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import ResetPassModal from "../Modal/ResetPassModal";

const SettingAccount = () => {
  const [statusModal, setStatusModal] = useState(false);
  const navigate = useNavigate();
  const accountExists = getAccount();
  return (
    <div className="boxContainer">
      <div className="boxWrapper">
        <div className="boxHead" style={{ justifyContent: "flex-start" }}>
          <h2 style={{ textTransform: "inherit" }}>
            Xin chào, {accountExists.username}
          </h2>
          {accountExists.role === "admin" && (
            <Space>
              <IsAdmin>ADMIN</IsAdmin>
              <GrUserAdmin color="#F30" size={"18px"} />
            </Space>
          )}
          <Button
            style={{ marginLeft: "auto" }}
            onClick={() => setStatusModal(true)}
          >
            Đổi mật khẩu
          </Button>
          <Button danger onClick={() => navigate("/logout")}>
            Đăng xuất
          </Button>
        </div>
        <Divider />
        <div className="boxBody">
          <ResetPassModal
            accountExists={accountExists}
            statusModal={statusModal}
            setStatusModal={setStatusModal}
          />
        </div>
      </div>
    </div>
  ); /* prettier-ignore */
};

export default SettingAccount;
