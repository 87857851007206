import React from "react";
import { Modal, Form, Input, Button, Checkbox, Typography } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { addAccountFn } from "../../helpers/account";
import SpinFullscreen from "../Spin/SpinFullscreen";
import { useForm } from "antd/es/form/Form";
import { useCollections } from "../../helpers/query";

const AddAccountModal = ({ accountExists, statusModal, setStatusModal }) => {
  const queryClient = useQueryClient();
  const [form] = useForm();
  const { isLoading: collectionsQuery_loading, data: collectionsQuery } = useCollections(); /* prettier-ignore */

  const { isLoading: add_account_loading, mutateAsync: add_account } =
    useMutation({
      mutationFn: (data) => addAccountFn(data),
      onSuccess: (data) => {
        if (data) {
          if (queryClient.getQueryData(["accounts"])) {
            queryClient.setQueryData(["accounts"], (odd) => {
              return {
                message: odd.message,
                accounts: [...odd.accounts, data.account],
              };
            });
          }
          toast.success(data.message);
          setStatusModal(false);
        }
      },
      onError: (error) => toast.error(error.message),
    });

  const saveModal = () => setStatusModal(false);
  const cancelModal = () => setStatusModal(false);
  const onAddAccount = (values) => {
    add_account(values);
    // form.resetFields();
  };
  if (collectionsQuery_loading || add_account_loading ) return <SpinFullscreen />; /* prettier-ignore */

  const options =
    collectionsQuery &&
    collectionsQuery.collections &&
    collectionsQuery.collections.map((collection) => {
      return {
        label: collection.title,
        value: collection.handle,
      };
    });

  return (
    <Modal
      classNames={{ header: "addAccountModalHeader", body: "addAccountModalBody" }} /* prettier-ignore */
      title={<Typography.Title level={3}>Tạo tài khoản quản trị</Typography.Title>} /* prettier-ignore */
      onOk={saveModal}
      onCancel={cancelModal}
      open={statusModal}
      centered
      footer={false}
    >
      <Form
        form={form}
        onFinish={onAddAccount}
        labelCol={{ span: 6 }}
        labelAlign="left"
      >
        <Form.Item
          label="Tài khoản"
          name="username"
          rules={[
            {
              required: true,
              message: "Tên tài khoản không được để trống!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Mật khẩu"
          name="password"
          rules={[
            {
              required: true,
              message: "Mật khẩu cũ không được để trống!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Phân quyền"
          name="permissions"
          labelCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: "Vui lòng chọn ít nhất một hạng mục!",
            },
          ]}
        >
          <Checkbox.Group options={options} />
        </Form.Item>
        <div className="text-right">
          <Button htmlType="submit" type="primary">
            Khởi tạo
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddAccountModal;
