import React from "react";

const NotFoundMain = () => {
  return (
    <div className="boxContainer">
      <div className="boxWrapper" style={{ height: "calc(100vh - 30px)" }}>
        <div className="boxBody h-100 text-center space-block align-items-center justify-content-center">
          <img alt="Coming Soon" src="./coming.jpg" />
        </div>
      </div>
    </div>
  );
};

export default NotFoundMain;
