import React, { useState } from "react";
import { Table, Button, Typography, Space, Popconfirm, Tag } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { deleteAccountFn } from "../../helpers/account";
import { toast } from "react-toastify";
import SpinFullscreen from "../Spin/SpinFullscreen";
import UpdateAccountModal from "../Modal/UpdateAccountModal";

const ListEmployee = ({ accountData }) => {
  const [statusModal, setStatusModal] = useState(false);
  const [userid, setUserid] = useState("");
  const [username, setUserName] = useState("");
  const [userpermissions, setUserpermissions] = useState([]);
  const queryClient = useQueryClient();
  const { isLoading: delete_account_loading, mutateAsync: delete_account } =
    useMutation({
      mutationFn: (id) => deleteAccountFn(id),
      onSuccess: (data) => {
        if (data) {
          toast.success(data.message);
          queryClient.invalidateQueries(["accounts"]);
        }
      },
      onError: (error) => toast.error(error.message),
    });

  const handleUpdateAccount = (id, username, permissions) => {
    setUserid(id);
    setUserName(username);
    setUserpermissions([...permissions]);
    setStatusModal(true);
  };

  const deleteAccount = (id) => delete_account(id);
  if (delete_account_loading) return <SpinFullscreen />;
  const columns = [
    {
      title: "Tài khoản",
      dataIndex: "username",
      key: "username",
      width: "40%",
    },
    {
      title: "Phân quyền",
      dataIndex: "permission",
      key: "permission",
      width: "40%",
    },
    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      width: "2-%",
    },
  ];
  const data =
    accountData &&
    accountData.accounts &&
    accountData.accounts.map((account, key) => {
      return {
        key: key,
        username: <Typography.Text title={account.username} strong>{account.username}</Typography.Text> /* prettier-ignore */,
        permission: account.permissions.map((permission) => (
          <Tag key={permission}>{permission.toUpperCase()}</Tag>
        )),
        action: (
          <Space>
            <Popconfirm title="Bạn có chắc muốn xóa tài khoản này?" cancelText="Hủy bỏ" okText="Tiếp tục" onConfirm={() => deleteAccount(account._id)}>
              <Button size="small" danger>Xóa</Button>
            </Popconfirm>
            <Button size="small" type="primary" onClick={() => handleUpdateAccount(account._id, account.username, account.permissions)}>Cập nhật</Button>
          </Space> /* prettier-ignore */
        ),
      };
    });

  return (
    <>
      <Table columns={columns} dataSource={data} size="large" pagination={false}/>
      <UpdateAccountModal userid={userid} username={username} userpermissions={userpermissions} statusModal={statusModal} setStatusModal={setStatusModal}/>
    </>
    /* prettier-ignore */
  );
};

export default ListEmployee;
