import axios from "axios";
import { toast } from "react-toastify";
axios.defaults.withCredentials = true;
axios.defaults.baseURL = "https://starfish-app-x5aio.ondigitalocean.app/server"; /* prettier-ignore */
// axios.defaults.baseURL = "http://localhost:5000/server"; /* prettier-ignore */
export const getAccountsFn = async () => {
  try {
    const response = await axios.get(`/account/info`);
    return response.data;
  } catch (error) {
    if (error.response) {
      if (error.response.data) toast.error(error.response.data.message);
    } else toast.error(error.message);
  }
};

export const getAccountFn = async (id) => {
  try {
    const response = await axios.get(`/account/info/${id}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      if (error.response.data) toast.error(error.response.data.message);
    } else toast.error(error.message);
  }
};

export const addAccountFn = async (data) => {
  try {
    const response = await axios.post(`/account/create`, data);
    return response.data;
  } catch (error) {
    if (error.response) {
      if (error.response.data) toast.error(error.response.data.message);
    } else toast.error(error.message);
  }
};

export const updateAccountFn = async (id, data) => {
  try {
    const response = await axios.post(`/account/info/${id}`, data);
    return response.data;
  } catch (error) {
    if (error.response) {
      if (error.response.data) toast.error(error.response.data.message);
    } else toast.error(error.message);
  }
};

export const deleteAccountFn = async (id) => {
  try {
    const response = await axios.delete(`/account/info/${id}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      if (error.response.data) toast.error(error.response.data.message);
    } else toast.error(error.message);
  }
};

export const loginAccountFn = async (data) => {
  try {
    const response = await axios.post(`/account/login`, data);
    return response.data;
  } catch (error) {
    if (error.response) {
      if (error.response.data) toast.error(error.response.data.message);
    } else toast.error(error.message);
  }
};
