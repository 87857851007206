import React from "react";
import { FloatButton, Layout } from "antd";
import { LuRefreshCw } from "react-icons/lu";
import SiderComponent from "../Sider/SiderComponent";
import { useQueryClient } from "react-query";
const { Content } = Layout;
const DefaultComponent = ({ children }) => {
  const queryClient = useQueryClient();
  const refreshAll = () => {
    queryClient.resetQueries();
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <FloatButton icon={<LuRefreshCw />} type="primary" onClick={refreshAll} />
      <SiderComponent />
      <Content>{children}</Content>
    </Layout>
  );
};

export default DefaultComponent;
