import { useQuery } from "react-query";
import { toast } from "react-toastify";
import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.baseURL = "https://starfish-app-x5aio.ondigitalocean.app/server"; /* prettier-ignore */
// axios.defaults.baseURL = "http://localhost:5000/server"; /* prettier-ignore */

export const useCollections = (id) => {
  return useQuery({
    queryKey: ["collections"],
    queryFn: async () => {
      try {
        const response = await axios.get(`/collections`);
        return response.data;
      } catch (error) {
        if (error.response) {
          if (error.response.data) toast.error(error.response.data.message);
        } else toast.error(error.message);
      }
    },
    onError: (error) => {
      console.log(error.message);
    },
  });
};

export const useCollection = (id) => {
  return useQuery({
    queryKey: ["collection", id],
    queryFn: async () => {
      try {
        const response = await axios.get(`/collections/${id}`);
        return response.data;
      } catch (error) {
        if (error.response) {
          if (error.response.data) toast.error(error.response.data.message);
        } else toast.error(error.message);
      }
    },
    onError: (error) => toast.error(error.message),
  });
};

export const useProducts = () => {
  return useQuery({
    queryKey: ["products"],
    queryFn: async () => {
      try {
        const response = await axios.get(`/products`);
        return response.data;
      } catch (error) {
        if (error.response) {
          if (error.response.data) toast.error(error.response.data.message);
        } else toast.error(error.message);
      }
    },
    onError: (error) => toast.error(error.message),
  });
};

// For Metafields Default
export const useMetafields = (date) => {
  return useQuery({
    queryKey: ["metafields", date],
    queryFn: async () => {
      try {
        const response = await axios.get(`/metafields/default?date=${date}`); /* prettier-ignore */
        return response.data;
      } catch (error) {
        if (error.response) {
          if (error.response.data) toast.error(error.response.data.message);
        } else toast.error(error.message);
      }
    },
    onError: (error) => toast.error(error.message),
  });
};

export const useMetafieldsDie = (weekday) => {
  return useQuery({
    queryKey: ["metafieldsDie", weekday],
    queryFn: async () => {
      try {
        const response = await axios.get(`/metafields/die?weekday=${weekday}`); /* prettier-ignore */
        return response.data;
      } catch (error) {
        if (error.response) {
          if (error.response.data) toast.error(error.response.data.message);
        } else toast.error(error.message);
      }
    },
    onError: (error) => toast.error(error.message),
  });
};

export const deleteMetafieldsFn = async (id) => {
  try {
    const response = await axios.delete(`/metafields/${id}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      if (error.response.data) toast.error(error.response.data.message);
    } else toast.error(error.message);
  }
};

export const addMetafieldsFn = async (data) => {
  try {
    const response = await axios.post(`/metafields/add`, data); /* prettier-ignore */
    return response.data;
  } catch (error) {
    if (error.response) {
      if (error.response.data) toast.error(error.response.data.message);
    } else toast.error(error.message);
  }
};

// For Account
export const useAccounts = async () => {
  return useQuery({
    queryKey: ["accounts"],
    queryFn: async () => {
      try {
        const response = await axios.get(`/account/info`);
        return response.data;
      } catch (error) {
        if (error.response) {
          if (error.response.data) toast.error(error.response.data.message);
        } else toast.error(error.message);
      }
    },
    onError: (error) => toast.error(error.message),
  });
};
