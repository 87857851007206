import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Button, Form, Input } from "antd";
import { BoxLogin, BoxLoginButton, BoxLoginTitle, BoxLoginWrap } from "./style";
import { useMutation } from "react-query";
import { loginAccountFn } from "../../helpers/account";
import { toast } from "react-toastify";
import { getAccount } from "../../helpers/auth";
import SpinFullscreen from "../../components/Spin/SpinFullscreen";

const LoginMain = () => {
  const useExists = getAccount();
  const navigate = useNavigate();
  const { isLoading: login_loading, mutateAsync: loginMutation } = useMutation({
    mutationFn: (data) => loginAccountFn(data),
    onSuccess: (data) => {
      if (data) {
        localStorage.setItem("user", JSON.stringify(data?.account));
        navigate("/home");
      }
    },
    onError: (error) => toast.error(error.message),
  });

  if (useExists) return <Navigate to="/home" />;
  if (login_loading) return <SpinFullscreen />;
  return (
    <BoxLogin>
      <BoxLoginWrap>
        <BoxLoginTitle>Đăng nhập</BoxLoginTitle>
        <Form
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 18,
          }}
          style={{ width: "500px" }}
          name="login"
          onFinish={(values) => loginMutation(values)}
          autoComplete="off"
        >
          <Form.Item
            label="Tên đăng nhập"
            name="username"
            rules={[
              {
                required: true,
                message: "Tên đăng nhập không được để trống!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Mật khẩu"
            name="password"
            rules={[
              {
                required: true,
                message: "Mật khẩu không được để trống!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <BoxLoginButton>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </BoxLoginButton>
        </Form>
      </BoxLoginWrap>
    </BoxLogin>
  );
};

export default LoginMain;
