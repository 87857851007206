import React, { Fragment } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { routes } from "./helpers/router";
import { CheckAccount } from "./helpers/auth";
import { ToastContainer } from "react-toastify";
import DefaultComponent from "./components/Default/DefaultComponent";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return ( 
    <Router>
      <Routes>
        {routes.map((route, i) => {
          const Layout = route.isNav ? DefaultComponent : Fragment;
          const element = <Layout>{route.element}</Layout>;
          if(route.isPrivate){
            return <Route key={i} path={route.path} element={<CheckAccount>{element}</CheckAccount>}/> /* prettier-ignore */
          }else if(!route.isPrivate) return <Route key={i} path={route.path} element={element} /> /* prettier-ignore */
          else return false;
        })} 
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
