import React from "react";
import { Spin } from "antd";

const SpinFullscreen = () => {
  return (
    <Spin fullscreen spinning size="large">
      <div className="content" />
    </Spin>
  );
};

export default SpinFullscreen;
