import React from "react";
import { Menu } from "antd";
import { WrapperSider } from "./style";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SpinFullscreen from "../Spin/SpinFullscreen";
import { getAccount } from "../../helpers/auth";

/* List Icon */
import { MdSportsVolleyball } from "react-icons/md";
import { RiLogoutBoxLine } from "react-icons/ri";
import { FaRegQuestionCircle, FaHandPeace, FaHome } from "react-icons/fa";
import { GrConfigure } from "react-icons/gr";
import { useCollections } from "../../helpers/query";
import { Header } from "antd/es/layout/layout";
/* End Icon */

const SiderComponent = () => {
  const useExists = getAccount();
  const navigate = useNavigate();
  const location = useLocation();
  const onClickMenu = (e) => {
    if (!e.key.includes("/")) return false;
    navigate(`${e.key}`);
  };
  const { isLoading: collection_items_loading, data: collection_items } = useCollections(); /* prettier-ignore */
  if (collection_items_loading) return <SpinFullscreen />; /* prettier-ignore */

  let items = [
    {
      key: `/home`,
      label: `Xin chào, ${useExists.username}`,
      icon: <FaHandPeace />,
    },
    {
      key: `haravan`,
      label: <Link to="https://sancaulonghungphu.myharavan.com/admin" target="_blank">Quản trị Haravan</Link>,
      icon: <FaHome />,
    } /* prettier-ignore */,
  ];

  collection_items?.collections &&
    collection_items.collections.forEach((collection, key) => {
      var colInPermission = useExists.permissions.find(permission => permission === collection.handle) /* prettier-ignore */
      if (useExists.role !== "admin") if (!colInPermission) return false;
      var data = {
        key: `/collections/${collection.id}`,
        handle: collection.handle,
        label: collection.title,
        icon: <MdSportsVolleyball />,
      };
      items.push(data);
    });

  items.push(
    {
      key: `/huong-dan`,
      label: `Hướng dẫn`,
      icon: <FaRegQuestionCircle />,
    },
    {
      key: `/setting`,
      label: `Cài đặt`,
      icon: <GrConfigure />,
    },
    {
      key: `/logout`,
      label: `Đăng xuất`,
      icon: <RiLogoutBoxLine />,
    }
  );
  return (
    <WrapperSider>
      <Header
        style={{
          position: "sticky",
          top: 0,
          left: 0,
          zIndex: 1,
          width: "100%",
          display: "flex",
          alignItems: "center",
          padding: 0,
        }}
      >
        <Menu
          theme="dark"
          defaultOpenKeys={["quan-8-san-hung-phu"]}
          defaultSelectedKeys={[`${location.pathname}`]}
          items={items}
          mode="horizontal"
          onClick={onClickMenu}
          style={{ flex: 1, minWidth: 0 }}
        />
      </Header>
    </WrapperSider>
  );
};

export default SiderComponent;
