import React from "react";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";
import { deleteAccount } from "../../helpers/auth";

const LogoutMain = () => {
  deleteAccount();
  Cookies.remove("access_token");
  return <Navigate to="/login" />;
};

export default LogoutMain;
