import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { queryClient } from "./helpers/global";

import { io } from "socket.io-client";
export const socket = io("https://starfish-app-x5aio.ondigitalocean.app/"); /* prettier-ignore */
// export const socket = io("https://1628-2405-4802-901f-5ce0-717b-9db0-6229-2d22.ngrok-free.app/"); /* prettier-ignore */
socket.on("order-created", (data) => {
  const key = data?.key;
  if (queryClient.getQueryData(["metafields", key])) {
    queryClient.setQueryData(["metafields", key], (odd) => {
      return {
        message: data.message,
        metafields: [...odd.metafields, data],
      };
    });
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <App />
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);
