import React from "react";
import { Modal, Typography, Form, Input, Button, Checkbox } from "antd";
import { useForm } from "antd/es/form/Form";
import { useMutation, useQueryClient } from "react-query";
import { updateAccountFn } from "../../helpers/account";
import { toast } from "react-toastify";
import SpinFullscreen from "../Spin/SpinFullscreen";
import { useCollections } from "../../helpers/query";

const UpdateAccountModal = ({
  userid,
  username,
  userpermissions,
  statusModal,
  setStatusModal,
}) => {
  const [form] = useForm();
  const queryClient = useQueryClient();
  const saveModal = () => setStatusModal(false);
  const cancelModal = () => setStatusModal(false);

  const { isLoading: collectionsQuery_loading, data: collectionsQuery } =
    useCollections();

  const { isLoading: update_account_loading, mutateAsync: update_account } =
    useMutation({
      mutationFn: (data) => updateAccountFn(userid, data),
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries(["accounts"]);
          toast.success(data.message);
          setStatusModal(false);
        }
      },
      onError: (error) => toast.error(error.message),
    });

  const onUpdateAccount = (values) => {
    form.resetFields();
    update_account(values);
  };

  if (collectionsQuery_loading || update_account_loading) return <SpinFullscreen />; /* prettier-ignore */

  const options =
    collectionsQuery &&
    collectionsQuery.collections &&
    collectionsQuery.collections.map((collection) => {
      return {
        label: collection.title,
        value: collection.handle,
      };
    });

  return (
    <Modal
      classNames={{ header: "addAccountModalHeader", body: "addAccountModalBody" }} /* prettier-ignore */
      title={<Typography.Title level={3}>Cập nhật phân quyền</Typography.Title>} /* prettier-ignore */
      onOk={saveModal}
      onCancel={cancelModal}
      open={statusModal}
      centered
      footer={false}
    >
      <Form
        form={form}
        onFinish={onUpdateAccount}
        labelCol={{ span: 6 }}
        labelAlign="left"
        initialValues={{
          username: username,
        }}
      >
        <Form.Item
          hidden
          label="Phân quyền"
          name="type"
          initialValue="permissions"
        >
          <Input />
        </Form.Item>
        <Form.Item label="Tài khoản" name="username">
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Phân quyền"
          name="permissions"
          labelCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: "Vui lòng chọn ít nhất một hạng mục!",
            },
          ]}
        >
          <Checkbox.Group options={options} />
        </Form.Item>
        <div className="text-right">
          <Button htmlType="submit" type="primary">
            Cập nhật
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default UpdateAccountModal;
