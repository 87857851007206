import React from "react";
import SettingAccount from "../../components/Setting/SettingAccount";
import { Space } from "antd";
import SettingEmployee from "../../components/Setting/SettingsEmployee";
import { getAccount } from "../../helpers/auth";

const SettingMain = () => {
  const useExists = getAccount();
  return (
    <Space className="space-flex" direction="vertical">
      <SettingAccount />
      {useExists.role === "admin" && <SettingEmployee />}
    </Space>
  );
};

export default SettingMain;
