import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";
const keyAccount = "user";
export const getAccount = () => {
  return JSON.parse(localStorage.getItem(keyAccount));
};
export const saveAccount = (data) => {
  localStorage.setItem(keyAccount, data);
};
export const deleteAccount = () => {
  localStorage.removeItem(keyAccount);
};
export const CheckAccount = ({ children }) => {
  const accessToken = Cookies.get("access_token");
  if (!accessToken) return <Navigate to="/logout" />;
  const accountExists = getAccount();
  if (!accountExists) return <Navigate to="/login" />;
  return children;
};
