import { Button, Divider } from "antd";
import React, { useState } from "react";
import { getAccount } from "../../helpers/auth";
import AddAccountModal from "../Modal/AddAccountModal";
import { useQuery } from "react-query";
import { getAccountsFn } from "../../helpers/account";
import { toast } from "react-toastify";
import SpinFullscreen from "../Spin/SpinFullscreen";
import ListEmployee from "../Accounts/ListEmployee";

const SettingEmployee = () => {
  const [statusModal, setStatusModal] = useState(false);
  const accountExists = getAccount();
  const { isLoading: account_items_loading, data: account_items } = useQuery({
    queryKey: ["accounts"],
    queryFn: () => getAccountsFn(),
    refetchOnWindowFocus: false,
    onError: (error) => toast.error(error.message),
  });
  if (account_items_loading) return <SpinFullscreen />;
  return (
    <div className="boxContainer">
      <div className="boxWrapper">
        <div className="boxHead">
          <h2>Thiết lập phân quyền</h2>
          <Button type="primary" onClick={() => setStatusModal(true)}>
            Thêm tài khoản quản trị
          </Button>
        </div>
        <Divider />
        <div className="boxBody">
          <ListEmployee accountData={account_items} />
          <AddAccountModal
            accountExists={accountExists}
            statusModal={statusModal}
            setStatusModal={setStatusModal}
          />
        </div>
      </div>
    </div>
  );
};

export default SettingEmployee;
